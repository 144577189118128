<!-- logo -->
<div class="container-fluid center">
  <div>
    <img class="logo" src="assets/images/logo-1-1024x194.png" alt="logo"/>
  </div>


  <h1 class="highlight">LEACHSTOCK 2022</h1>

</div>
<!-- part image -->
<div style="margin: 20px auto; text-align: center; background: #000;">
  <img style="margin: 20px auto; text-align: center; display: inline-block;"
       src="assets/images/leach-crowd-1024x480.jpg" alt="Leachettes "/>
</div>

<!-- content -->
<div class="container-fluid center">

  <h1 class="center">Coming up ...</h1>
  <h2></h2>

  <h1>July 23, 2022</h1>

  <div><img src="/assets/images/leachstock-2022.jpeg" alt="Leachstock 2022"/></div>

  <br/>
  <div class="container">
    <h3>That’s rite! </h3>

    <p>Well......It looks like there's gonna be another Leachstock but it ain't gonna quite be the same. Sluggo, Duggie,
      freight train Owens is down for the count. We're gonna fill in the blanks and still rock our brains out with the
      new band tentatively named "Dirtbag" We have plucked songs from the Leaches, Bad Choices, and written more new
      stuff. Pretty sure Teo's band Lowfall's gonna be there (from last year, they're really f*ckin good!) and Regan's
      got some others on the line. This year is gonna benefit Kitsap Humane Society.......
      Kinda stoked we even get to do this again! More to come.....
      <br/>
    <h2>July 23rd at the usual location</h2>
    <br/>
    <p>And remember ... </p>
    <p class="highlight">WE WILL ALWAYS BE YOUR LEACH! ROCK ON!!!</p>
    <div>
      <a href="https://www.facebook.com/The-Thirsty-Leaches-122529424460599" target="_blank">
        <img src="assets/images/facebook-icon.png" alt="facebook link" style="max-width: 100px;"></a>
    </div>
    <h3>
      <a style="margin: 20px 0 30px;" href="https://goo.gl/maps/kw8fXUA4eTUD7G6t5" target="_blank">
        10595 NE CUMMINGS CT, KINGSTON, WA
      </a>
    </h3>
  </div>

  <div>
    <img style="margin: 20px 0 30px;" src="assets/images/featured-telecaster-1024x194.jpg" alt="Leach Me "/>
  </div>

</div>

<!-- images -->
<section style="background: #000;">
  <div class="container-fluid center">
  <span class="col-sm-12 col-md-6 col-lg-6">
    <a href="https://goo.gl/maps/kw8fXUA4eTUD7G6t5" target="_blank">

      <img style="max-width: 290px;" src="../assets/images/ft-map.png" alt="map to party"/>
    </a>
  </span>

    <span class="col-sm-12 col-md-6 col-lg-6">
    <img style="max-width: 290px;" src="assets/images/40-years.jpg" alt="40 years of Leach"/>
  </span>

  </div>


</section>
<div class="container-fluid center">

  <img style="margin: 20px 20px 30px;" src="assets/images/doug-t-shirt.jpg" alt="Leaches T shirt"/>
  <img style="margin: 20px 0 30px;" src="assets/images/doug-t-shirt-back.jpg" alt="Leaches T shirt"/>


</div>

